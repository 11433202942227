import React from 'react';
import axios from 'axios'

const api = 'https://api.inchbyinch.eu'


function CMS(props) {
  const [freeze, setFreeze] = React.useState(false)

  const handleCoverSubmit = (event) => {
    event.preventDefault();
    setFreeze(true)
    var formData = new FormData(event.target);

    axios({
        method: 'post',
        url: api + '/cms/images',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
    })
    .then(()=>{
        setFreeze(false)
        alert("updated")
        // hello
    })
}
  const handleAGBSubmit = (event) => {
    event.preventDefault();
    setFreeze(true)
    var formData = new FormData(event.target);

    axios({
        method: 'post',
        url: api + '/cms/agb',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
    })
    .then(()=>{
        setFreeze(false)
        alert("updated")
        // hello
    })
}

    return (
      <div className="page">
        <h2>CMS</h2>



        <h4>Hero Images</h4>
        <form onSubmit={handleCoverSubmit}>
                    <label htmlFor="leftImage">left Image</label> &nbsp;
                    <input type="file" name="leftImage"></input><br/><br/>
                    <label htmlFor="rightImage">right Image</label> &nbsp;
                    <input type="file" name="rightImage"></input><br/><br/>
                    <input type="submit" value="Submit" disabled={freeze}></input>
        </form><br/><br/>

        <h4>AGB</h4>
        <form onSubmit={handleAGBSubmit}>
                    <label htmlFor="imprint">Imprint</label> &nbsp;
                    <input type="file" name="imprint"></input><br/><br/>
                    <label htmlFor="agbde">AGB DE</label> &nbsp;
                    <input type="file" name="agbde"></input><br/><br/>
                    <label htmlFor="agben">AGB EN</label> &nbsp;
                    <input type="file" name="agben"></input><br/><br/>
                    <input type="submit" value="Submit" disabled={freeze}></input>
        </form>
      </div>
    );
  }


export default CMS