import React from 'react';

function Home(props) {

    const [password, setPassword] = React.useState("")

    return (
      <div>
        <h2>InchByInch Office</h2>
        Password:
        <input value={password} type="password" onChange={(event => setPassword(event.target.value))}></input>
        <button onClick={()=> props.handleLogin(password)}>Login</button>
      </div>
    );
  }


export default Home