import React from 'react';
import axios from 'axios'

const api = 'https://api.inchbyinch.eu'
const cdn = 'https://couch.inchbyinch.eu/label/'

function Genre() {
    const [genre, setGenre] = React.useState([])
    const [reload, setReload] = React.useState(false)

    React.useEffect(()=>{
        async function fetchData(){
            let res = await axios.get(api + '/genre')
            let data = []

            setGenre(res.data.genres)
        }
        fetchData()
    }, [reload])

    const handleRemove = (event) => {
        let item = event.target.getAttribute('data-item')
        console.log(item)

        axios({
            method: 'delete',
            url: api +'/genre',
            data: {name: item},
            headers: {'Content-Type': 'application/json' }
        })
        setReload(!reload)
        // axios.delete('https://api.inchbyinch.eu/genre', {name: item})
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        var formData = new FormData(event.target);

        axios.post(api + '/genre', formData)
        .then(()=>{
            setReload(!reload)
        })
    }

    return (
      <div className="page">
        <h2>Genres</h2>

        <ul>
            {genre.map((item)=>(
                <div style={{margin: "1em"}} key={item}><button  data-item={item} onClick={handleRemove}>remove</button> {item} </div>
            ))}
        </ul>


        <form onSubmit={handleSubmit}>
            <label htmlFor="name">Add Genre </label>
            <input name="name" type="text"></input>
            <input type="submit" value="Submit"></input>
        </form>
      </div>
    );
  }


export default Genre