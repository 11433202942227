import React from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
const api = 'https://api.inchbyinch.eu'
// const cdn = 'https://couch.inchbyinch.eu/catalog/'
// import BasicTable from './table'

const socket = io(api);


function Sync() {
  const [log, setLog] = React.useState('connecting to sync socket...')
  React.useEffect(()=>{
    socket.on('sync', d=>{
      setLog(d)
    })
  }, [])

  const handleSync = () => {
    // axios.get(api + '/catalog/sync').then((res) => {
    //   alert("Sync Success: " + res.data.updateSuccess)
    // })
    socket.emit('sync', true)
  }




  return (
    <div>
      <button onClick={handleSync}>Sync with SevDesk</button>

      <div>
        {log}
      </div>
      <br/><br/>

      <a href={api + "/backup.tar"}>Download Encrypted Backup</a>
    </div>
  );
}


export default Sync
