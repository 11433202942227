import React from 'react';
import axios from 'axios'

const api = 'https://api.inchbyinch.eu'
const cdn = 'https://couch.inchbyinch.eu/label/'


function Label() {
    const [label, setlabel] = React.useState([])
    const [reload, setReload] = React.useState(false)

    React.useEffect(()=>{
        async function fetchData(){
            let res = await axios.get(api + '/label')
            let data = []
            res.data.forEach(l=>{
              data.push(l.doc)
            })
            console.log(data)
            setlabel(data)
        }
        fetchData()
    }, [reload])

    const handleRemove = async (event) => {
        let item = event.target.getAttribute('data-item')
        console.log(item)

        let ok = await axios({
            method: 'delete',
            url: api + '/label',
            data: {slug: item},
            headers: {'Content-Type': 'application/json' }
        })
        console.log("removed")
        setReload(!reload)
        // axios.delete('https://api.inchbyinch.eu/label', {name: item})
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        var formData = new FormData(event.target);

        axios({
            method: 'post',
            url: api + '/label',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(()=>{
            setReload(!reload)
        })
    }

    return (
      <div className="page">
        <h2>Label</h2>
        <form onSubmit={handleSubmit}>
            <label htmlFor="name">Name </label><br/>
            <input name="name" type="text"></input><br/><br/>
            <label htmlFor="name">Logo </label><br/>
            <input name="logo" type="file"></input><br/><br/>
            <input type="submit" value="Submit"></input>
        </form>
        <hr/>
        <div>
            {label.map((item)=>(
                <div style={{marginBottom: "1em"}} key={item.slug}><button data-item={item.slug} onClick={handleRemove}>remove</button> {item.name} <img src={cdn + item.slug +'/'  + item.logo} alt="logo" width="128"></img>  </div>
            ))}
        </div>



      </div>
    );
  }


export default Label