import React from 'react';
import './App.css';

import Home from './pages/Home'
import Catalog from './pages/Catalog'
import Genre from './pages/Genre'
import Label from './pages/Label';
import CMS from './pages/CMS';
import Sync from './pages/Sync';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {



  return (
    <div className="App">
     
    <Router>
        <div>
          <ul className="menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/catalog">Catalog</Link>
            </li>
            <li>
              <Link to="/label">Label</Link>
            </li>
            <li>
              <Link to="/genre">Genre</Link>
            </li>
            <li>
              <Link to="/cms">CMS</Link>
            </li>
            <li>
              <Link to="/sync">Sync</Link>
            </li>
          </ul>

          <hr />

          {/*
            A <Switch> looks through all its children <Route>
            elements and renders the first one whose path
            matches the current URL. Use a <Switch> any time
            you have multiple routes, but you want only one
            of them to render at a time
          */}
          <Switch>
            <PrivateRoute exact path="/">
              <Catalog />
            </PrivateRoute>
            <PrivateRoute path="/catalog">
              <Catalog />
            </PrivateRoute>
            <PrivateRoute path="/label">
              <Label />
            </PrivateRoute>
            <PrivateRoute path="/genre">
              <Genre />
            </PrivateRoute>
            <PrivateRoute path="/cms">
              <CMS/>
            </PrivateRoute>
            <PrivateRoute path="/sync">
              <Sync/>
            </PrivateRoute>
          </Switch>
        </div>
      </Router>
    </div>
  );
}



function PrivateRoute({ children, ...rest }) {
  const [auth, setAuth] = React.useState(false)

  const handleLogin = (pw) => {
    if(pw === "#funkplace111"){
      setAuth(true)
    }
  }

  return (
    <>
    {auth === false ? 

    <Home handleLogin={handleLogin}/>
    :
    <Route {...rest} >{children}</Route> 

    }

    </>
  );
}




export default App;
