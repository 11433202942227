import React from 'react';
import styled from 'styled-components';

import axios from 'axios'
import DataTable from 'react-data-table-component';
import crypto from 'crypto'

const api = 'https://api.inchbyinch.eu'
// const api = 'http://localhost:5002'
const cdn = 'https://couch.inchbyinch.eu/catalog/'


const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const FilterComponent = ({ filterText, onFilter, onClear, onSync }) => (
  <>
    <button onClick={onSync}>Sync with SevDesk</button> &nbsp;&nbsp;&nbsp;
    <TextField id="search" type="text" placeholder="Search" value={filterText} onChange={onFilter} />
    <ClearButton type="button" onClick={onClear}>X</ClearButton>
  </>
);

const columns = [
  {
    name: 'Label Code',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Titel',
    selector: 'text',
    sortable: true,
  },
  {
    name: 'Preis',
    selector: 'price',
    sortable: true,
    right:true
  },
  {
    name: 'Stock',
    selector: 'stock',
    sortable: true,
    right: true
  },
  {
    name: 'Release Date',
    selector: 'releaseDate',
    sortable: true,
  },
  {
    name: 'Active',
    selector: 'active',
    sortable: true,
    cell: row => <div data-tag="allowRowEvents"><div style={{ fontWeight: "bold" }}> <ActiveIcon active={row.active}/></div></div>,
  },

];


// const conditionalRowStyles = [
//     {
//       when: row => row.active === true,
//       style: {
//         backgroundColor: 'rgba(0, 60, 0,0.2)',
//         color: 'white',
//         '&:hover': {
//           cursor: 'pointer',
//         },
//       },
//     },
//   ];

const ActiveIcon = (props) => {
  return(
    <div style={{width: 10, height: 10, backgroundColor: props.active?'green':'red', borderRadius: "50%"}}></div>
  )
}


const BasicTable = () => {

  const [catalog, setCatalog] = React.useState([])
  const [genre, setGenre] = React.useState([])
  const [label, setLabel] = React.useState([])
  const [importlist, setImportlist]  = React.useState([])
  
  const [reload, setReload] = React.useState(false)

  React.useEffect(()=>{
      async function fetchData(){
          let gres = await axios.get(api + '/genre')
          setGenre(gres.data.genres)

      }
      fetchData()
  }, [])

  // React.useEffect(()=>{
  //     async function fetchData(){
  //         let gres = await axios.get(api + '/importlist')
  //         setImportlist(gres.data)

  //     }
  //     fetchData()
  // }, [])

  React.useEffect(()=>{
      async function fetchData(){
          let lbl = await axios.get(api + '/label')
          let lbls= []
          lbl.data.forEach(l=>{
            lbls.push(l.doc)
          })

          setLabel(lbls)

      }
      fetchData()
  }, [])


  React.useEffect(()=>{
      async function fetchData(){


          let res = await axios.get(api + '/catalog')
          let data = res.data
          // console.log(data)
          setCatalog(data)
      }
      fetchData()
  }, [reload])


  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = catalog.filter(item => JSON.stringify(item).toLowerCase().includes(filterText.toLowerCase()));

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const handleSync =  () => {
        axios.get(api + '/catalog/sync').then((res)=>{
            alert("Sync Success: " + res.data.updateSuccess)
        })
    }

    return <FilterComponent onSync={handleSync} onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      title="Catalog"
      columns={columns}
      data={filteredItems}
      pagination
      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
      subHeader
      striped
      expandableRows
      expandOnRowClicked
      subHeaderComponent={subHeaderComponentMemo}
    //   conditionalRowStyles={conditionalRowStyles}

    //   selectableRows
      persistTableHead
      expandableRowsComponent={<ReleaseEditor importlist={importlist} label={label} genre={genre} handleReload={()=>setReload(!reload)} />}
    />
  );
};



const ReleaseEditor = (props) => {

    const [freeze, setFreeze] = React.useState(false)

    const handleMetaSubmit = (event) => {
        event.preventDefault();
        setFreeze(true)
        var formData = new FormData(event.target);
        formData.set('id', props.data._id);

        // dat shitty checkbox
        if(formData.has("active")){
            formData.set('active', true)
        } else {
            formData.set('active', false)
        }

        // dat shitty checkbox
        if(formData.has("noPromo")){
            formData.set('noPromo', true)
        } else {
            formData.set('noPromo', false)
        }

        // dat shitty checkbox
        if(formData.has("explicit")){
            formData.set('explicit', true)
        } else {
            formData.set('explicit', false)
        }


        // dat shitty checkbox
        if(formData.has("preorder")){
            formData.set('preorder', true)
        } else {
            formData.set('preorder', false)
        }


        axios.post(api + '/updateRelease', formData)
        .then(()=>{
            setFreeze(false)
            alert("updated")
            props.handleReload()
        })
    }


    const handleCoverSubmit = (event) => {
        event.preventDefault();
        setFreeze(true)
        var formData = new FormData();
        var origFormData = new FormData(event.target)
        formData.set('id', props.data._id);
        
        for (var [key, value] of origFormData.entries()) {
            formData.set(key, value)
        }

        axios({
            method: 'post',
            url: api + '/coverupload',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(()=>{
            setFreeze(false)
            alert("updated")
            props.handleReload()
        })
    }

    const handleStaffPick = (event) => {
        event.preventDefault();
        setFreeze(true)
        var formData = new FormData(event.target);
        formData.set('id', props.data._id);
          // dat shitty checkbox
          if(formData.has("staffPick")){
            formData.set('staffPick', true)
        } else {
            formData.set('staffPick', false)
        }

        axios({
            method: 'post',
            url: api + '/staffpick',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then((res)=>{
            setFreeze(false)
            alert("Staff Pick Update: " +res.data.success)
            props.handleReload()
        })
    }

    const handleAssets = (event) => {
        event.preventDefault();
        setFreeze(true)
        axios({
            method: 'get',
            url: api + '/assets/'+ props.data._id,
        })
        .then((res)=>{
            setFreeze(false)
            alert("generate Assets " + res.data.success)
            props.handleReload()
        })
    }
    const handleInfoSheet = (event) => {
        event.preventDefault();
        setFreeze(true)
        axios({
            method: 'get',
            url: api + '/generateinfosheet/'+ props.data._id,
        })
        .then((res)=>{
            setFreeze(false)
            alert("generate Infosheet " + res.data.success)
            props.handleReload()
        })
    }



    const handlePreorder = (event) => {
        event.preventDefault();
        setFreeze(true)
        axios({
            method: 'get',
            url: api + '/preorder/'+ props.data._id,
        })
        .then((res)=>{
            setFreeze(false)
            alert("Notified Customers about the Release! " + res.data.success)
            props.handleReload()
        })
    }

    const handleSnippetSubmit = (event) => {
        event.preventDefault();
        setFreeze(true)
        var formData = new FormData();
        var origFormData = new FormData(event.target)
        formData.set('id', props.data._id);
        

        for(var pair of origFormData.entries()) {
            formData.append(pair[0], pair[1])
         }

        axios({
            method: 'post',
            url: api + '/snippetupload',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(()=>{
            setFreeze(false)
            alert("updated")
            props.handleReload()
        })
    }

    const handleFullAudioSubmit = (event) => {
        event.preventDefault();
        setFreeze(true)
        var formData = new FormData();
        var origFormData = new FormData(event.target)
        formData.set('id', props.data._id);
        

        for(var pair of origFormData.entries()) {
            formData.append(pair[0], pair[1])
         }

        axios({
            method: 'post',
            url: api + '/fullaudioupload',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(()=>{
            alert("updated")
            setFreeze(false)
            props.handleReload()
        })
    }

    return (
        <div className="release-form">
            
            
            <form onSubmit={handleMetaSubmit}>
                    {props.data._id} - <a href={"https://inchbyinch.eu/promo/" + props.data.partNumber + "x"+ crypto.randomBytes(2).toString("hex")}>PromoLink</a>
                    {props.data._id} - <a href={"https://inchbyinch.eu/feedback/" + props.data.partNumber + "x"+ crypto.randomBytes(2).toString("hex")}>FeedbackLink</a>
                    <label htmlFor="label">Label</label>

                    <select name="label" defaultValue={props.data.label}>
                        {props.label.map(item=>(
                            <option key={item.slug} value={item.slug}>{item.name}</option>
                        ))}
                    </select>

                    <label htmlFor="genre">Genre</label>

                    <select name="genre" defaultValue={props.data.genre}>
                        {props.genre.map(item=>(
                            <option key={item} value={item}>{item}</option>
                        ))}
                    </select>

                    <label htmlFor="genre2">Genre 2</label>

                    <select name="genre2" defaultValue={props.data.genre2}>
                        <option value="none">none</option>
                        {props.genre.map(item=>(
                            <option key={item} value={item}>{item}</option>
                        ))}
                    </select>

                    <label htmlFor="genre3">Genre 3</label>

                    <select name="genre3" defaultValue={props.data.genre3}>
                        <option value="none">none</option>
                        {props.genre.map(item=>(
                            <option key={item} value={item}>{item}</option>
                        ))}
                    </select>

                    <label htmlFor="description">Description</label>
                    <textarea name="description" rows="10" cols="30" defaultValue={props.data.description}></textarea>
                    <label htmlFor="remix">Remix</label>
                    <input name="remix" defaultValue={props.data.remix}></input>
                    <label htmlFor="remix">Format</label>
                    <input name="format" defaultValue={props.data.format}></input>
                    <label htmlFor="releaseDate">Release Date</label>
                    <input name="releaseDate" type="date" defaultValue={props.data.releaseDate}></input>

                    <div className="inline">
                        <input name="active" type="checkbox" defaultChecked={props.data.active}></input>
                        <label htmlFor="active">Active</label>
                    </div>

                    <div className="inline">
                        <input name="noPromo" type="checkbox" defaultChecked={props.data.noPromo}></input>
                        <label htmlFor="active">No Promo</label>
                    </div>

                    <div className="inline">
                        <input name="explicit" type="checkbox" defaultChecked={props.data.explicit}></input>
                        <label htmlFor="explicit">Explicit</label>
                    </div>
                    <div className="inline">
                        <input name="preorder" type="checkbox" defaultChecked={props.data.preorder}></input>
                        <label htmlFor="preorder">Preorder</label>
                    </div>
                 
                    <input type="submit" value="Submit" disabled={freeze}></input>
            </form>

            <div className="cover-col">
                <div className="covers">
                    <img className="cover" alt="coverFront" src={cdn + props.data._id +'/' + props.data.coverFront}/>
                    <img className="cover" alt="coverBack" src={cdn + props.data._id +'/' + props.data.coverBack}/>
                </div>
                
                <form onSubmit={handleCoverSubmit}>
                    <label htmlFor="coverFront">Cover Front</label>
                    <input type="file" name="coverFront"></input>
                    <label htmlFor="coverBack">Cover Back</label>
                    <input type="file" name="coverBack"></input>
                    <input type="submit" value="Submit" disabled={freeze}></input>
                </form>

                <hr/>
                <form onSubmit={handleStaffPick}>
                    <label htmlFor="importref">Staff Pick</label>
                    
                    <div className="inline">
                        <input name="staffPick" type="checkbox" defaultChecked={props.data.staffPick}></input>
                        <label htmlFor="staffPick">Staff Pick</label>
                    </div>
                    <input type="submit" value="Submit" disabled={freeze}></input>
                </form> 
                <form onSubmit={handleAssets}>
                    <label htmlFor="importref">Assets</label><br/>
                    {/* window.open('https://api.inchbyinch.eu/download/' + props.release.id + "/" + props.release.name  + "/infoSheet.pdf", '_blank'); */}
                    {(props.data._attachments && props.data._attachments["infoSheet.pdf"]) && <a target="_blank" href={'https://api.inchbyinch.eu/download/' + props.data.id + "/" + props.data.name +  "/infoSheet.pdf"}>Info Sheet</a>} 
                    {(props.data._attachments && props.data._attachments["shopkit.zip"]) && <a target="_blank" href={'https://api.inchbyinch.eu/download/' + props.data.id + "/" + props.data.name +  "/shopkit.zip"}>Shopkit</a>}
                    {(props.data._attachments && props.data._attachments["fullaudio.zip"] ) && <a target="_blank" href={'https://api.inchbyinch.eu/download/' + props.data.id + "/" + props.data.name +  "/fullaudio.zip"}>AudioZip</a>}<br/>
                    <input type="submit" value="Generate all Assets" disabled={freeze}></input>
                </form> 
                <form onSubmit={handleInfoSheet}>
                    <input type="submit" value="Generate Infosheet only" disabled={freeze}></input>
                </form>
                <form onSubmit={handlePreorder}>
                    <label htmlFor="importref">Preorder</label><br/>
                    <input type="submit" value="Disable Preorder+notify Customers" disabled={freeze}></input>
                </form> 
            </div>

            <div className="tracks-col">
                <div className="tracklist">
                    {!props.data.snippets ? null :props.data.snippets.map(track=>(
                        <div key={track.title}>{track.track} - {track.artist} - {track.title}</div>
                    ))}
                </div>
                <form onSubmit={handleSnippetSubmit}>
                    <label htmlFor="snippets">Snippets</label>
                    <input type="file" multiple name="snippets"></input>
                    <input type="submit" value="Submit" disabled={freeze}></input>
                </form>
                    
                <div className="tracklist">
                    {!props.data.fullAudio ? null : props.data.fullAudio.map(track=>(
                        <div key={track.title}>{track.track} - {track.artist} - {track.title}</div>
                    ))}
                </div>    
                <form onSubmit={handleFullAudioSubmit}>
                    <label htmlFor="fullAudio">Full Audio</label>
                    <input type="file" multiple name="fullAudio"></input>
                    <input type="submit" value="Submit" disabled={freeze}></input>
                </form>
            </div>




        </div>
    )
}

export default BasicTable