import React from 'react';
import BasicTable from './table'

function Catalog() {
    return (
      <div>
        <BasicTable/>
      </div>
    );
  }


export default Catalog